<template>
  <div>
    <binding v-if="isShow" @changeIsShow="changeIsShow" />
    <bound v-else @changeIsShow="changeIsShow" />
  </div>
</template>
<script>
import  * as EPWeChat from "../api.js"
import binding from "./binding";
import bound from "./bound";
export default {
  components: {
    binding,
    bound,
  },
  data() {
    return {
      isShow: true, //当前企业是否绑定企业微信账号,默认未绑定
    };
  },
  mounted() {
    /** 查询企业微信绑定 */
    let token = this.$CCDK.CCToken.getToken();
      EPWeChat.getEPWeChatSetUp({
        accessToken: token,
      })
      .then((res) => {
        if (null == res.data) {
          // 未绑定
          this.isShow = true;
        } else {
          // 已绑定
          this.isShow = false;
          // 保存名称和ID
          localStorage.setItem("corpid", res.data.corpid);
          localStorage.setItem("corpname", res.data.corpname);
        }
      });
  },
  methods: {
    /** 显示集成设置绑定页面 */
    changeIsShow(value) {
      this.isShow = value;
    },
  },
};
</script>
<template>
  <el-dialog :title="$t('front-epwechat-module-v1-self')" :visible.sync="dialogSynchro" width="600px">
    <el-form
      :model="ruleForm"
      :rules="ruleForms"
      ref="ruleForm"
      class="demo-ruleForm"
      size="mini"
    >
      <div class="synchro">
        <el-form-item :label="$t('front-epwechat-module-v1-appname')" prop="appname">
          <el-input
            v-model="ruleForm.appname"
            :placeholder="$t('front-epwechat-module-v1-input-appname')"
          ></el-input
        ></el-form-item>
        <el-form-item :label="$t('front-epwechat-module-v1-home-url')" prop="homepageurl">
          <el-input
            v-model="ruleForm.homepageurl"
            :placeholder="$t('front-epwechat-module-v1-input-home-url')"
          ></el-input
        ></el-form-item>
      </div>
      <div class="synchro">
        <p>{{ $t('front-epwechat-module-v1-info') }}</p>
        <p>{{ $t('front-epwechat-module-v1-info-desc') }}</p>
        <el-form-item label=" agentId：" prop="agentid">
          <el-input
            v-model="ruleForm.agentid"
            :placeholder="$t('front-epwechat-module-v1-input-agentid')"
          ></el-input
        ></el-form-item>
        <el-form-item label=" Secret：" prop="secret">
          <el-input
            v-model="ruleForm.secret"
            :placeholder="$t('front-epwechat-module-v1-input-secret')"
          ></el-input
        ></el-form-item>
        <!-- <el-form-item :label="$t('front-epwechat-module-v1-upload-file')"
          ><el-upload
            class="upload-import"
            ref="uploadImport"
            action="https://baidu.com/"
            :on-remove="handleRemove"
            :file-list="fileList"
            :limit="1"
            :on-change="handleChange"
            :auto-upload="false"
            accept=".txt"
            :name="ruleForm.appname"
          >
            <el-button
              v-show="!hasFile"
              slot="trigger"
              size="mini"
              type="primary"
              >选取文件</el-button
            >
            <el-button v-show="hasFile" size="mini" type="primary" disabled
              >选取文件</el-button
            >
          </el-upload></el-form-item
        > -->
      </div>
      <div class="synchro">
        <p>{{ $t('front-epwechat-module-v1-callback') }}</p>
        <p>{{ $t('front-epwechat-module-v1-callback-desc') }}</p>
        <el-form-item :label="$t('front-epwechat-module-v1-domain')">
          <div style="display: flex; width: 360px">
            <el-input
              v-model="ruleForm.domain"
              placeholder="lapp.ingageapp.com"
              id="domain"
              readonly
            ></el-input
            ><el-button
              icon="el-icon-document-copy"
              class="domain"
              @click="copyText(ruleForm.domain)"
            ></el-button>
          </div>
        </el-form-item>
      </div>
      <div class="synchro">
        <p>{{ $t('front-epwechat-module-v1-single') }}</p>
        <p>{{ $t('front-epwechat-module-v1-single-desc') }}</p>

        <el-form-item :label="$t('front-epwechat-module-v1-web-single')">
          <el-radio v-model="ruleForm.iswebsso" label="true">{{ $t('front-epwechat-module-v1-open') }}</el-radio>
          <el-radio v-model="ruleForm.iswebsso" label="false">{{ $t('label.close') }}</el-radio>
        </el-form-item>
        <div v-show="ruleForm.iswebsso == 'true'">
          <el-form-item :label="$t('front-epwechat-module-v1-authorize')">
            <div style="display: flex; width: 360px">
              <el-input
                v-model="ruleForm.webcreditcallback"
                placeholder="crm-p05.CloudCC.com"
                id="webcreditcallback"
                readonly
              ></el-input>
              <el-button
                icon="el-icon-document-copy"
                class="webcreditcallback"
                @click="copyText(ruleForm.webcreditcallback)"
              ></el-button>
            </div>
          </el-form-item>
        </div>
        <el-form-item :label="$t('front-epwechat-module-v1-ios-single')">
          <el-radio v-model="ruleForm.isiossso" label="true">{{ $t('front-epwechat-module-v1-open') }}</el-radio>
          <el-radio v-model="ruleForm.isiossso" label="false">{{ $t('label.close') }}</el-radio>
        </el-form-item>
        <div v-show="ruleForm.isiossso == 'true'">
          <el-form-item label="iOS schema：" prop="iosschema">
            <el-input
              v-model="ruleForm.iosschema"
              :placeholder="$t('front-epwechat-module-v1-input-iosschema')"
            ></el-input>
          </el-form-item>
          <el-form-item label="iOS Bundle ID：">
            <div style="display: flex; width: 360px">
              <el-input
                v-model="ruleForm.iosbundleid"
                placeholder="com.ingageapp.ingage"
                id="iosbundleid"
                readonly
              ></el-input>
              <el-button
                icon="el-icon-document-copy"
                class="iosbundleid"
                @click="copyText(ruleForm.iosbundleid)"
              ></el-button>
            </div>
          </el-form-item>
        </div>
        <el-form-item :label="$t('front-epwechat-module-v1-android-single')">
          <el-radio v-model="ruleForm.isandroidsso" label="true">{{ $t('front-epwechat-module-v1-open') }}</el-radio>
          <el-radio v-model="ruleForm.isandroidsso" label="false"
            >{{ $t('label.close') }}</el-radio
          >
        </el-form-item>
        <div v-show="ruleForm.isandroidsso == 'true'">
          <el-form-item label="Android schema：" prop="androidschema">
            <el-input
              v-model="ruleForm.androidschema"
              :placeholder="$t('front-epwechat-module-v1-input-androidschema')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('front-epwechat-module-v1-android-autograph')">
            <div style="display: flex; width: 360px">
              <el-input
                v-model="ruleForm.androidsignature"
                placeholder="B56BF2B4115F361C556D6018457F5E4E"
                id="androidsignature"
                readonly
              ></el-input>
              <el-button
                icon="el-icon-document-copy"
                class="androidsignature"
                @click="copyText(ruleForm.androidsignature)"
              ></el-button>
            </div>
          </el-form-item>
          <el-form-item :label="$t('front-epwechat-module-v1-android-packname')">
            <div style="display: flex; width: 360px">
              <el-input
                v-model="ruleForm.androidapppackage"
                placeholder="com.rkhd.ingage.app"
                id="androidapppackage"
                readonly
              ></el-input>
              <el-button
                icon="el-icon-document-copy"
                class="androidapppackage"
                @click="copyText(ruleForm.androidapppackage)"
              ></el-button>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="dialogSynchro = false">{{ $t('label.tabpage.cancel') }}</el-button>
      <el-button size="mini" type="primary" @click="saveSelfSetup"
        >{{ $t('label.tabpage.ok') }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import * as EPWeChat from "../api.js";
import {copyText} from "@/utils/copyInfo"
export default {
  data() {
    return {
      // fileList: [], // 文件列表
      // hasFile: false, // 是否禁止选取文件
      
      dataForm: {
        name: "",
        file: null,
      }, // 选取的文件
      dialogSynchro: false,
      ruleForm: {
        appname: "",
        homepageurl: "",
        agentid: "",
        secret: "",
        uploadcreditfile: "",
        domain: "",
        iswebsso: "true",
        webcreditcallback: "",
        isiossso: "false",
        iosschema: "",
        iosbundleid: "",
        isandroidsso: "false",
        androidschema: "",
        androidsignature: "",
        androidapppackage: "",
      },
      ruleForms: {
        appname: [
          { required: true, message: this.$i18n.t('front-epwechat-module-v1-input-appname'), trigger: "blur" },
        ],
        homepageurl: [
          { required: true, message: this.$i18n.t('front-epwechat-module-v1-input-home-url'), trigger: "blur" },
        ],
        agentid: [
          { required: true, message: this.$i18n.t('front-epwechat-module-v1-input-agentid'), trigger: "blur" },
        ],
        secret: [{ required: true, message: this.$i18n.t('front-epwechat-module-v1-input-secret'), trigger: "blur" }],
        iosschema: [
          { required: true, message: this.$i18n.t('front-epwechat-module-v1-input-iosschema'), trigger: "blur" },
        ],
        androidschema: [
          { required: true, message: this.$i18n.t('front-epwechat-module-v1-input-androidschema'), trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    copyText,
    /** 打开弹窗 */
    
    open(value) {
      if (value == "open") {
        this.dialogSynchro = true;
      } else if (value == "edit") {
        this.dialogSynchro = true;
        // 查询企业微信自建应用设置信息
        EPWeChat.getEPWeChatSelfSetUp({
          orgid: localStorage.getItem("orgId"),
        }).then((res) => {
          if (res.returnCode == 100) {
            this.ruleForm.appname = res.data.appname;
            this.ruleForm.homepageurl = res.data.homepageurl;
            this.ruleForm.agentid = res.data.agentid;
            this.ruleForm.secret = res.data.secret;
            this.ruleForm.uploadcreditfile = res.data.uploadcreditfile;
            this.ruleForm.domain = res.data.domain;
            this.ruleForm.iswebsso = res.data.iswebsso;
            this.ruleForm.webcreditcallback = res.data.webcreditcallback;
            this.ruleForm.isiossso = res.data.isiossso;
            this.ruleForm.iosschema = res.data.iosschema;
            this.ruleForm.iosbundleid = res.data.iosbundleid;
            this.ruleForm.isandroidsso = res.data.isandroidsso;
            this.ruleForm.androidschema = res.data.androidschema;
            this.ruleForm.androidsignature = res.data.androidsignature;
            this.ruleForm.androidapppackage = res.data.androidapppackage;
          }
        });
      }
    },
    /** 保存企业微信自建应用设置信息 */
    saveSelfSetup() {
      let params = {
        appname: this.ruleForm.appname,
        homepageurl: this.ruleForm.homepageurl,
        agentid: this.ruleForm.agentid,
        secret: this.ruleForm.secret,
        uploadcreditfile: this.ruleForm.uploadcreditfile,
        domain: this.ruleForm.domain,
        iswebsso: this.ruleForm.iswebsso,
        webcreditcallback: this.ruleForm.webcreditcallback,
        isiossso: this.ruleForm.isiossso,
        iosschema: this.ruleForm.iosschema,
        iosbundleid: this.ruleForm.iosbundleid,
        isandroidsso: this.ruleForm.isandroidsso,
        androidschema: this.ruleForm.androidschema,
        androidsignature: this.ruleForm.androidsignature,
        androidapppackage: this.ruleForm.androidapppackage,
      };
      EPWeChat.saveEPWeChatSelfSetUp(params).then((res) => {
        if (res.returnCode == 100) {
          // 保存成功
          this.$emit("changeAddSelfSetUp", false);
          this.dialogSynchro = false;
        }
      });
    },
    /** 文件列表移除文件时的钩子 */
    
    handleRemove(file, fileList) {
      if (!fileList.length) {
        this.hasFile = false;
      }
      this.dataForm.file = null;
    },
    /** 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用 */
    
    handleChange(file, fileList) {
      if (fileList.length >= 2) {
        return;
      }
      if (fileList.length === 1) {
        this.hasFile = true;
      }
      this.dataForm.file = file;
      this.ruleForm.appname = file.name;
    },
  },
};
</script>

<style lang="scss">
$left: 153px;
.synchro {
  p {
    padding: 0;
    margin: 10px 0;
  }
  p:first-child {
    font-size: 14px;
    color: #006dcc;
  }
  p:nth-child(2) {
    color: #666666;
  }
  .el-input {
    width: 360px;
  }
  .el-form-item__label {
    font-size: 14px;
    width: $left;
    text-align: left;
  }
  .el-form-item__error {
    left: $left;
  }
  .el-upload-list {
    margin-left: 145px;
  }
}
</style>
<template>
  <!-- 同步弹窗 -->
  <el-dialog :title="title" :visible.sync="dialogSynchro" width="600px">
    <!-- 通讯录同步 -->
    <div v-if="title == $t('front-epwechat-module-v1-addressbook-sync')">
      <div class="synchro">
        <p>{{ $t('front-epwechat-module-v1-info') }}</p>
        <p>{{ $t('front-epwechat-module-v1-info-desc') }}</p>
        <el-form
          :model="addressBook"
          :rules="addressBooks"
          ref="ruleForm"
          class="demo-ruleForm"
          size="mini"
        >
          <el-form-item label=" Secret：" prop="secret">
            <!-- 请输入Secret -->
            <el-input
              v-model="addressBook.secret"
              :placeholder="$t('front-epwechat-module-v1-input-secret')"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 日程同步 -->
    <div v-if="title == $t('front-epwechat-module-v1-schedule-sync')">
      <div class="synchro">
        <p>{{ $t('front-epwechat-module-v1-info') }}</p>
        <p>{{ $t('front-epwechat-module-v1-info-desc') }}</p>
        <el-form
          :model="schedule"
          :rules="schedules"
          ref="ruleForm"
          class="demo-ruleForm"
          size="mini"
        >
          <el-form-item label=" Secret：" prop="secret">
            <el-input
              v-model="schedule.secret"
              :placeholder="$t('front-epwechat-module-v1-input-secret')"
            ></el-input>
          </el-form-item>
          <el-form-item label=" Token：" prop="token">
            <el-input
              v-model="schedule.token"
              :placeholder="$t('front-epwechat-module-v1-input-token')"
            ></el-input>
          </el-form-item>
          <el-form-item label=" EncodingAESKey：" prop="encodingAESKey">
            <el-input
              v-model="schedule.encodingAESKey"
              :placeholder="$t('front-epwechat-module-v1-input-EncodingAESKey')"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="synchro">
        <p>{{ $t('front-epwechat-module-v1-callback') }}</p>
        <p>{{ $t('front-epwechat-module-v1-callback-desc') }}</p>
        <el-form
          :model="calendar"
          ref="ruleForm"
          class="demo-ruleForm"
          size="mini"
        >
          <el-form-item label="  URL：">
            <div style="display: flex; width: 360px">
              <el-input
                v-model="calendar.url"
                readonly
                id="calendarurl"
              ></el-input>
              <el-button
                icon="el-icon-document-copy"
                @click="copyText(calendar.url)"
                class="calendarUrl"
              ></el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 企业微信联系人同步 -->
    <div v-if="title == $t('front-epwechat-module-v1-contacts-sync')">
      <div class="synchro">
        <p>{{ $t('front-epwechat-module-v1-info') }}</p>
        <p>{{ $t('front-epwechat-module-v1-info-desc') }}</p>
        <el-form
          :model="contact"
          :rules="contacts"
          ref="ruleForm"
          class="demo-ruleForm"
          size="mini"
        >
          <el-form-item label=" Secret：" prop="secret">
            <el-input
              v-model="contact.secret"
              :placeholder="$t('front-epwechat-module-v1-input-secret')"
            ></el-input>
          </el-form-item>
          <el-form-item label=" Token：" prop="token">
            <el-input
              v-model="contact.token"
              :placeholder="$t('front-epwechat-module-v1-input-token')"
            ></el-input>
          </el-form-item>
          <el-form-item label=" EncodingAESKey：" prop="encodingAESKey">
            <el-input
              v-model="contact.encodingAESKey"
              :placeholder="$t('front-epwechat-module-v1-input-EncodingAESKey')"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="synchro">
        <p>{{ $t('front-epwechat-module-v1-callback') }}</p>
        <p>{{ $t('front-epwechat-module-v1-callback-desc') }}</p>
        <el-form
          :model="contacturl"
          ref="ruleForm"
          class="demo-ruleForm"
          size="mini"
        >
          <el-form-item label="  URL：">
            <div style="display: flex; width: 360px">
              <el-input
                v-model="contacturl.url"
                readonly
                id="contact"
              ></el-input>
              <el-button
                icon="el-icon-document-copy"
                @click="copyText(contacturl.url)"
                class="contacturl"
              ></el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="dialogSynchro = false">{{ $t('label.tabpage.cancel') }}</el-button>
      <el-button size="mini" type="primary" @click="sureSynchro"
        >{{ $t('label.tabpage.ok') }}</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as EPWeChat from "../api.js";
import { copyText} from "@/utils/copyInfo"
export default {
  data() {
    return {
      dialogSynchro: false, // 同步弹窗
      title: "",
      addressBook: {
        secret: "",
      }, // 通讯录同步
      addressBooks: {
        secret: [{ required: true, message: this.$i18n.t('front-epwechat-module-v1-input-secret'), trigger: "blur" }],
      },
      schedule: {
        secret: "",
        token: "",
        encodingAESKey: "",
      }, // 日程同步
      schedules: {
        secret: [{ required: true, message: this.$i18n.t('front-epwechat-module-v1-input-secret'), trigger: "blur" }],
        token: [{ required: true, message: this.$i18n.t('front-epwechat-module-v1-input-token'), trigger: "blur" }],
        encodingAESKey: [
          { required: true, message: this.$i18n.t('front-epwechat-module-v1-input-EncodingAESKey'), trigger: "blur" },
        ],
      },
      contact: {
        secret: "",
        token: "",
        encodingAESKey: "",
      }, // 联系人同步
      contacts: {
        secret: [{ required: true, message: this.$i18n.t('front-epwechat-module-v1-input-secret'), trigger: "blur" }],
        token: [{ required: true, message: this.$i18n.t('front-epwechat-module-v1-input-token'), trigger: "blur" }],
        encodingAESKey: [
          { required: true, message: this.$i18n.t('front-epwechat-module-v1-input-EncodingAESKey'), trigger: "blur" },
        ],
      },
      
      addressbook: {
        url: "",
      }, // 通讯录同步URL
      calendar: {
        url: "",
      }, // 日程同步URL
      contacturl: {
        url: "",
      }, // 联系人同步url
    };
  },
  methods: {
    copyText,
    /** 打开弹窗 */
    
    open(title) {
      this.dialogSynchro = true;
      this.title = title;
    },
    /** 确定-保存同步设置 */
    sureSynchro() {
      // 同步不同传参不同
      let params = {};
      if (this.title == this.$i18n.t('front-epwechat-module-v1-addressbook-sync')) {
        let value = this.addressBook.secret; // 空，value=false
        params = value
          ? {
              issynchronizaddressbook: "true", //是否开启通讯录同步
              addressbooksecret: this.addressBook.secret, //通讯录同步secret,开启通讯录同步时必填
            }
          : false;
      } else if (this.title == this.$i18n.t('front-epwechat-module-v1-schedule-sync')) {
        let value =
          this.schedule.secret &&
          this.schedule.token &&
          this.schedule.encodingAESKey;
        params = value
          ? {
              issynchronizschedule: "true", //是否开启日程同步
              schedulesecret: this.schedule.secret, //日程同步secret,日程同步开启时必填
              scheduletoken: this.schedule.token, //日程同步token,日程同步开启时必填
              scheduleencodingaeskey: this.schedule.encodingAESKey, //日程同步key,日程同步开启时必填
            }
          : false;
      } else if (this.title == this.$i18n.t('front-epwechat-module-v1-contacts-sync')) {
        let value =
          this.contact.secret &&
          this.contact.token &&
          this.contact.encodingAESKey;
        params = value
          ? {
              issysnchronizcontact: "true", //是否开启企业微信联系人同步
              contactsecret: this.contact.secret, //联系人同步secret,客户同步开启时必填
              contacttoken: this.contact.token, //联系人同步token,客户同步开启时必填
              contactencondingaeskey: this.contact.encodingAESKey, //联系人同步key,客户同步开启时必填
            }
          : false;
      }
      // 保存企业微信同步设置信息
      if (params) {
        EPWeChat.saveEPWeChatSyncSetUp(params).then((res) => {
          if (res.returnCode == 100) {
            this.dialogSynchro = false;
            this.$emit("sureSynchro");
            if(params.issynchronizaddressbook){
              // 调左侧菜单接口
              this.$bus.$emit('refreshgetSetupList')
            }
            // 同步成功
            this.$message.success(this.$i18n.t('label.companyprofile.syncsuccess'))
          }
        });
      } else {
        this.$message.warning(this.$i18n.t('vue_label_commonobjects_detail_check_the_content'));
      }
    },
    /** 回显-查询同步设置 */
    
    configEcho() {
      /** 查询企业微信同步设置信息 */
      EPWeChat.getEPWeChatSyncSetUp({
        orgid: localStorage.getItem("orgId"),
      }).then((res) => {
        if (res.data) {
          // 通讯录
          this.addressBook.secret = res.data.addressbooksecret;
          // 日程
          this.schedule.secret = res.data.schedulesecret;
          this.schedule.token = res.data.scheduletoken;
          this.schedule.encodingAESKey = res.data.scheduleencodingaeskey;
          // 联系人
          this.contact.secret = res.data.contactsecret;
          this.contact.token = res.data.contacttoken;
          this.contact.encodingAESKey = res.data.contactencondingaeskey;
        }
      });
      /** 获取回调url */
      this.configUrl()
    },
    /** 回显-获取回调URL */
    
    configUrl(){
      EPWeChat.getCallBackurl().then((res) => {
        if (res.data) {
          this.addressbook.url = res.data.addressbookurl;
          this.calendar.url = res.data.calendarurl;
          this.contacturl.url = res.data.contacturl;
        }
      });
    },
  },
};
</script>
<style lang="scss">
$left: 153px;
.synchro {
  p {
    padding: 0;
    margin: 10px 0;
  }
  p:first-child {
    font-size: 14px;
    color: #006dcc;
  }
  p:nth-child(2) {
    color: #666666;
  }
  .el-input {
    width: 360px;
  }
  .el-form-item__label {
    font-size: 14px;
    width: $left;
    text-align: left;
  }
  .el-form-item__error {
    left: $left;
  }
}
</style>
<template>
  <div class="inte-border">
    <p class="title">{{ $t('front-epwechat-module-v1-epwechat-integrate') }}</p>
    <p class="integrat-text-color" v-html="$t('front-epwechat-module-v1-epwechat-describe')"></p>
    <el-form
      :model="wechatForm"
      :rules="rules"
      ref="wechatForm"
      class="demo-ruleForm integrat"
      size="mini"
    >
      <el-form-item :label="$t('front-epwechat-module-v1-corpid')" prop="id">
        <el-input v-model="wechatForm.id" :placeholder="$t('front-epwechat-module-v1-input-corpid')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('label.tianyancha.name')" prop="name">
        <el-input
          v-model="wechatForm.name"
          :placeholder="$t('front-epwechat-module-v1-input-name')"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('wechatForm')"
          >{{ $t('front-epwechat-module-v1-binding') }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import  * as EPWeChat from "../api.js"
export default {
  data() {
    return {
      wechatForm: {
        id: "", //wwe9c071914818a7d6,wwefd2d449c0088079
        name: "", //北京神州云动科技股份有限公司
      },
      rules: {
        id: [{ required: true, message: this.$i18n.t('front-epwechat-module-v1-input-corpid'), trigger: "blur" },
              { pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,  message: '必须是是字母与数字的结合', trigger: ['blur', 'change'] }],
        name: [{ required: true, message: this.$i18n.t('front-epwechat-module-v1-input-name'), trigger: "blur" }],
      },
    };
  },
  methods: {
    /** 绑定企业微信 */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            // 接口-绑定企业微信
            EPWeChat.saveEPWeChatSetUp({
              corpid: this.wechatForm.id,
              corpname: this.wechatForm.name,
            })
            .then((res) => {
              if (res.returnCode == 10000) {
                // 存储 id和名称
                localStorage.setItem("corpid", this.wechatForm.id);
                localStorage.setItem("corpname", this.wechatForm.name);
                this.$emit('changeIsShow',false);//进入到同步设置页面
              }
            })
        }else {
          // 请输入内容
          this.$message.warning(this.$i18n.t('label.tabpage.entercontent'))
        }
      });
    },
  },
};
</script>
<style lang="scss">
$left: 170px;
.inte-border {
  margin: 20px 15px;
  .title {
    font-size: 18px;
    color: #333333;
    font-weight: 700;
  }
  .integrat-text-color {
    color: #666;
    margin: 15px 0;
  }
  .integrat {
    .el-input {
      width: 200px;
    }
    .el-form-item__label {
      font-size: 14px;
      width: $left;
      text-align: left;
    }
    .el-form-item__error {
      left: $left;
    }
  }
}
</style>
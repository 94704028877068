<template>
  <div>
    <!-- 已绑定企业 -->
    <div class="inte-boder">
      <div class="title">
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ $t('front-epwechat-module-v1-bound-enterprise') }}<i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              class="dropdown-color"
              @click.native="dialogUnboundEnterpriseWechat = true"
              >{{ $t('front-epwechat-module-v1-unbound') }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="content">
        <div class="content-list">
          <div>
            <p>{{ $t('front-epwechat-module-v1-id') }} : {{corpid}}</p>
            <p>{{ $t('label.tianyancha.name') }} : {{corpname}}</p>
          </div>
          <!-- 私有云 -->
          <div v-if="cloudccVersion">
            <el-button v-show="addSelfSetUp" size="mini" type="primary" @click="openSelfSetUp">{{ $t('front-epwechat-module-v1-add-selfapp') }}</el-button>
            <div v-show="!addSelfSetUp">
              <el-button size="mini" type="primary" @click="editSelfSetup">{{ $t('front-epwechat-module-v1-edit-selfapp') }}</el-button>
              <el-button size="mini" type="primary" @click="removeSelfSetup">{{ $t('label.chatter.delete') }}</el-button>
            </div>
          </div>
          <!-- 公有云 -->
          <div v-else>
            <el-button v-show="isInstall" size="mini" type="primary" @click="installApp"
              >{{ $t('front-epwechat-module-v1-install-app') }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 同步设置 -->
    <div class="inte-boder">
      <div class="title">
        <p>{{ $t('lable.outlook.detail.synset') }}</p>
      </div>
      <div class="content">
        <div
          class="content-list"
          v-for="(item, index) in synchroData"
          :key="index"
        >
          <div class="content-text">
            <p>{{ item.title }}</p>
            <p class="color666">{{ item.desc }}</p>
          </div>
          <div>
            <!-- 开启 -->
            <span v-if="item.isTrue">
              <el-button
                v-for="(ele) in item.btn.openBtn"
                :key="ele.value"
                size="mini"
                type="primary"
                @click.native="openDialogSynchro(item.title,ele,index)"
                >{{ ele.btnText }}</el-button
              >
            </span>
            <!-- 配置、关闭 -->
            <el-button
              v-else
              v-for="(ele) in item.btn.closeBtn"
              :key="ele.value"
              size="mini"
              type="primary"
              @click.native="openDialogSynchro(item.title,ele,index)"
              >{{ ele.btnText }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 解绑弹窗 -->
    <el-dialog
      :title="$t('front-epwechat-module-v1-unbound-WeChat')"
      :visible.sync="dialogUnboundEnterpriseWechat"
      width="500px"
    >
      <div>
        <ul class="unbound-dialog">
          <li>{{ $t('front-epwechat-module-v1-unbound-tips') }}</li>
          <li>{{ $t('front-epwechat-module-v1-unbound-item') }}</li>
          <li>{{ $t('front-epwechat-module-v1-unbound-item2') }}</li>
          <li>{{ $t('front-epwechat-module-v1-unbound-item3') }}</li>
          <li>{{ $t('front-epwechat-module-v1-unbound-item4') }}</li>
          <li>{{ $t('front-epwechat-module-v1-unbound-item5') }}</li>
        </ul>
        <el-checkbox v-model="agreeUnbound" class="agree-unbound"
          >{{ $t('front-epwechat-module-v1-agree-unbound') }}</el-checkbox
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogUnboundEnterpriseWechat = false"
          >{{ $t('label.tabpage.cancel') }}</el-button
        >
        <el-button
          size="mini"
          type="primary"
          @click="queryUnbound()"
          >{{ $t('label.tabpage.ok') }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 同步弹窗 -->
    <dialogSynchro ref="dialogSynchro" @sureSynchro="sureSynchro"></dialogSynchro>
    <!-- 自建应用弹窗 -->
    <dialogSelfsetup ref="dialogSelfsetup" @changeAddSelfSetUp="changeAddSelfSetUp"></dialogSelfsetup>
  </div>
</template>
<script>
import  * as EPWeChat from "../api.js"
import dialogSynchro from "./dialogSynchro" // 同步设置弹窗
import dialogSelfsetup from "./dialogSelfsetup" // 自建应用弹窗
export default {
  components: {
    dialogSynchro,
    dialogSelfsetup
  },
  data() {
    return {
      corpid:localStorage.getItem('corpid'),// 企业微信ID
      corpname:localStorage.getItem('corpname'),// 企业名称
      cloudccVersion: "", // 私有云or公有云
      addSelfSetUp: true, // 添加/编辑自建应用
      isInstall: "",//是否要安装应用
      installUrl: "",//安装应用地址
      /** 同步设置 */
      synchroData: [
        {
          title: this.$i18n.t('front-epwechat-module-v1-addressbook-sync'),
          desc: this.$i18n.t('front-epwechat-module-v1-addressbook-desc'),
          isTrue:true,
          btn: {
            openBtn: [
              {
                btnText: this.$i18n.t('front-epwechat-module-v1-open'),
                value: "open",
              },
            ],
            closeBtn: [
              {
                btnText: this.$i18n.t('label.textmessage.vonage.configurations'),
                value: "config",
              },
              {
                btnText: this.$i18n.t('label.close'),
                value: "close",
              },
            ]
          }
        },
        {
          title:this.$i18n.t('front-epwechat-module-v1-schedule-sync'),
          desc: this.$i18n.t('front-epwechat-module-v1-schedule-desc'),
          isTrue:true,
          btn: {
            openBtn: [
              {
                btnText: this.$i18n.t('front-epwechat-module-v1-open'),
                value: "open",
              },
            ],
            closeBtn: [
              {
                btnText: this.$i18n.t('label.textmessage.vonage.configurations'),
                value: "config",
              },
              {
                btnText: this.$i18n.t('label.close'),
                value: "close",
              },
            ]
          }
        },
        {
          title: this.$i18n.t('front-epwechat-module-v1-contacts-sync'),
          desc: this.$i18n.t('front-epwechat-module-v1-contacts-desc'),
          isTrue:true,
          btn: {
            openBtn: [
              {
                btnText: this.$i18n.t('front-epwechat-module-v1-open'),
                value: "open",
              },
            ],
            closeBtn: [
              {
                btnText: this.$i18n.t('label.textmessage.vonage.configurations'),
                value: "config",
              },
              {
                btnText:  this.$i18n.t('label.close'),
                value: "close",
              },
            ]
          }
        },
      ],
      dialogUnboundEnterpriseWechat: false, // 解绑企业微信
      agreeUnbound: false, // 同意解绑
      btnValue: "",//按钮的value值
      currentIndex: "",//当前下标
    };
  },
  mounted(){
    /** 公有云or私有云 */
    EPWeChat.getEPWeChatUserAppVersion()
    .then(res => {
      // returnCode,100是私有云，0是公有云
      if(res.returnCode == 100){
        this.cloudccVersion = true
        /** 查询企业微信自建应用设置信息 */
        EPWeChat.getEPWeChatSelfSetUp({
          orgid: localStorage.getItem("orgId"),
        }).then(res => {
          if(null == res.data){
            // 未添加
            this.addSelfSetUp = true
          }else {
            // 已添加
            this.addSelfSetUp = false
          }
        })
      }else if(res.returnCode == 0) {
        this.cloudccVersion = false
        /** 查询企业微信是否已安装应用 */
        EPWeChat.isInstallEPWeChat({
          corpid: this.corpid,
          url:'https%3A%2F%2Fk8sszyd.cloudcc.com%2F%23%2Femptypage'
        })
        .then((res) => {
          if(res.returnCode == 10000){  
            // 已装
            this.isInstall = false;
          } else if(res.returnCode == 10001){
            // 未装
            this.isInstall = true;
            this.installUrl = res.data.url
          }
        })
      }
    })
    
    /** 查询企业微信同步设置信息 */
    EPWeChat.getEPWeChatSyncSetUp({
      orgid: localStorage.getItem('orgId')
    })
    .then(res => {
      if(res.data){
          this.synchroData[0].isTrue = !res.data.issynchronizaddressbook
          this.synchroData[1].isTrue = !res.data.issynchronizschedule
          this.synchroData[2].isTrue = !res.data.issysnchronizcontact
        }else {
          this.synchroData.forEach(item => {
            item.isTrue = true
          })
      }
    })
  },
  methods: {
    /** 打开同步设置弹窗 */
    openDialogSynchro(title,btn,index) {
      if(btn.value == 'open'){
        // 打开
        this.$refs.dialogSynchro.open(title,btn);
        this.btnValue = btn.value;
        this.currentIndex = index;
        this.$refs.dialogSynchro.configUrl()
      }else if(btn.value == "config"){
        // 配置
        this.$refs.dialogSynchro.open(title,btn);
        this.btnValue = btn.value;
        this.currentIndex = index;
        this.$refs.dialogSynchro.configEcho()
      }else if(btn.value == 'close'){
        // 关闭
        // 接口-保存企业微信同步设置信息
        let params = {}
        if(title == this.$i18n.t('front-epwechat-module-v1-addressbook-sync')){
            params = {
                issynchronizaddressbook: "false",//是否开启通讯录同步
            }
        }else if(title == this.$i18n.t('front-epwechat-module-v1-schedule-sync')){
            params = {
                issynchronizschedule: "false",//是否开启日程同步
            }
        }else if(title == this.$i18n.t('front-epwechat-module-v1-contacts-sync')){
            params = {
                issysnchronizcontact: "false",//是否开启企业微信联系人同步
            }
        }
        EPWeChat.saveEPWeChatSyncSetUp(params)
        .then(res => {
            if (100 == res.returnCode) {
              this.synchroData[index].isTrue = true;// 相对应的按钮显示为开启
              if(params.issynchronizaddressbook){
              // 调左侧菜单接口
              this.$bus.$emit('refreshgetSetupList')
            }
            }
        })
      }

    },
    /** 确认同步 */
    sureSynchro(){
      if(this.btnValue == 'open'){
        this.synchroData[this.currentIndex].isTrue = false;
      }
    },
    /** 确认解绑 */
    queryUnbound(){
      if(this.agreeUnbound == true){
        // 接口-解除绑定
        EPWeChat.unBoundEPWeChat({
          corpid: localStorage.getItem('corpid'),
          corpname: localStorage.getItem('corpname')
        })
        .then(res => {
          if (res.returnCode == 10000) { 
            // 移除id和名称
            localStorage.removeItem('corpid');
            localStorage.removeItem('corpname');
            this.dialogUnboundEnterpriseWechat = false;
            this.$emit('changeIsShow',true);
            // 调左侧菜单接口
            this.$bus.$emit('refreshgetSetupList')
          }
        })
      }else {
        this.$message.warning(this.$i18n.t('front-epwechat-module-v1-tick-unbound'))
      }
    },
    /** 安装应用 */
    installApp(){
      window.open(this.installUrl);//打开扫码页面

      let PollingInterface = setInterval(() => {
        EPWeChat.isInstallEPWeChat({
          corpid: this.corpid,
          url:'https%3A%2F%2Fk8sszyd.cloudcc.com%2F%23%2Femptypage'
        }).then(res => {
          if (10000 == res.returnCode) {
            // 已装好，结束轮询,刷新页面
            clearInterval(PollingInterface)
            location.reload();
          }
        })
      }, 3000);

    },
    /** 打开自建应用 */
    openSelfSetUp(){
      this.$refs.dialogSelfsetup.open('open')
    },
    /** 改变自建应用的按钮 */
    changeAddSelfSetUp(value){
      this.addSelfSetUp = value
    },
    /** 编辑自建应用 */
    editSelfSetup(){
      this.$refs.dialogSelfsetup.open('edit')
    },
    /** 删除自建应用 */
    removeSelfSetup(){
      this.$confirm(this.$i18n.t('front-epwechat-module-v1-removesele-desc'), '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '保存',
          cancelButtonText: '取消',
          type:'warning'
        })
          .then(() => {
            // 解除绑定
            EPWeChat.unBoundEPWeChat({
              corpid: localStorage.getItem('corpid'),
              corpname: localStorage.getItem('corpname')
            })
            .then(res => {
              if(res.returnCode == 10000){
                this.addSelfSetUp = true
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          });
    }
  },
};
</script>
<style lang="scss" scoped>
// 解绑
.el-dropdown-link {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.dropdown-color {
  color: #006dcc;
}
// 同步设置
.inte-boder {
  border: 1px solid #dedcda;
  border-radius: 3px;
  margin: 20px 10px;
  .title {
    height: 40px;
    background: #fafaf9;
    border-bottom: 1px solid #dedcda;
    padding: 10px;
    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      padding: 0;
    }
  }
  .content {
    .content-list {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333333;
      font-weight: 500;
      p {
        margin: 5px;
        padding: 0;
      }
      .color666 {
        color: #666666;
      }
      .content-text{
        min-width: 800px;
      }
    }
  }
}
// 解绑弹窗
.unbound-dialog {
  font-size: 12px;
  color: #666666;
  li {
    margin: 10px 0;
  }
  li:first-child {
    font-size: 14px;
  }
}
</style>
<style>
/* 解绑弹窗 */
.agree-unbound .el-checkbox__label {
  font-size: 14px;
  color: #333333;
  font-weight: 500;
}
</style>